// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---gatsby-cache-gatsby-mdx-mdx-wrappers-dir-74-fe-3189549-d-5-fa-22-d-1-aa-9-ddac-32-c-3-c-7-scope-hash-216-fd-2886-a-4-eb-651-dcb-7-b-06-d-675-ecd-19-js": () => import("/Users/matuzalemteles/Documents/clay/clayui.com/gatsby/.cache/gatsby-mdx/mdx-wrappers-dir/74fe3189549d5fa22d1aa9ddac32c3c7--scope-hash-216fd2886a4eb651dcb7b06d675ecd19.js" /* webpackChunkName: "component---gatsby-cache-gatsby-mdx-mdx-wrappers-dir-74-fe-3189549-d-5-fa-22-d-1-aa-9-ddac-32-c-3-c-7-scope-hash-216-fd-2886-a-4-eb-651-dcb-7-b-06-d-675-ecd-19-js" */),
  "component---src-templates-docs-js": () => import("/Users/matuzalemteles/Documents/clay/clayui.com/src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */),
  "component---src-pages-index-js": () => import("/Users/matuzalemteles/Documents/clay/clayui.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-try-html-js": () => import("/Users/matuzalemteles/Documents/clay/clayui.com/src/pages/try.html.js" /* webpackChunkName: "component---src-pages-try-html-js" */)
}

exports.data = () => import("/Users/matuzalemteles/Documents/clay/clayui.com/.cache/data.json")

